import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import Video from "./components/video";
import Store from "./store";

const App: FC = observer(() => {

  const [ store ] = useState(() => new Store())
  useEffect(() => store.init(), [ store ])

  const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.code === "Enter" && !e.ctrlKey) {
      if (!e.currentTarget.value) return
      store.sendMessage(e.currentTarget.value)
      e.currentTarget.value = ""
      e.preventDefault()
    }
  }

  const setPreferredLayer = (userId: string, streamName: string, layer: number) => {
    store.peer.setPreferredLayer(userId, streamName, layer)
  }

  return (
    <div>
      <p>Status: {store.peer.status}</p>
      {store.roomInfo && <p>Room uuid: {store.roomInfo.uuid}</p>}
      <p>Watch the console</p>
      <p>Users count: {store.peer.users.size+1}</p>
      <div style={{ display: "flex", width: 300 }}>
        <button onClick={() => store.peer.toggleConstraint("audio")}>
          { store.peer.getConstraint("audio")? "Disable": "Enable" } Microphone
        </button>
        <button onClick={() => store.peer.toggleConstraint("video")}>
          { store.peer.getConstraint("video")? "Disable": "Enable"} WebCam
        </button>
      </div>
      <div>
        <button onClick={() => store.peer.toggleConstraint("video", "screenSharing") }>
          { store.peer.getConstraint("video", "screenSharing")? "Stop": "Start" } ScreenSharing
        </button>
      </div>
      <div style={{ display: "flex" }}>
        { Array.from(store.peer.users).map(([ userId, user ]) => user.rawStreams.length > 0 && (
          <div key={userId} style={{ display: "flex", flexDirection: "column" }}>
            { user.rawStreams.map(stream => (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Video stream={stream.mediaStream} controls={true} key={stream.streamName} />
                {stream.constraints.video && (
                  <div>
                    <button onClick={() => setPreferredLayer(user.id, stream.streamName, 0)}>Layer 0</button>
                    <button onClick={() => setPreferredLayer(user.id, stream.streamName, 1)}>Layer 1</button>
                    <button onClick={() => setPreferredLayer(user.id, stream.streamName, 2)}>Layer 2</button>
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
      <textarea placeholder={`You are ${store.peer.currentUser?.userInfo.name}`} className="chat-input" onKeyDown={onKeyDown}>
      </textarea>
      <div className="messages-list">
        {store.messages.map((item, index) => (
          <div key={index}>{item.name ?? ("User " + item.contactId)}: {item.text}</div>
        ))}
      </div>

      <div style={{ marginTop: "16px" }}>
        <button onClick={() => store.toggleRecord()}>{store.activeRecording? "Stop Record": "Start Record *"}</button>
      </div>
      <div style={{ marginTop: "16px" }}>
        <button onClick={() => store.closeRoom()}>Close room for all</button>
      </div>
      <div style={{ marginTop: "16px" }}>
        <button onClick={() => store.tryReconnect()}>Try Reconnect</button> 
      </div>
    </div>
  )
})

export default App