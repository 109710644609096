type Options = Partial<{
  method: "GET" | "POST" | "DELETE" | "PUT"
}>

export class HttpError extends Error {
  code: number

  body?: any
  error?: any
  
  constructor(response: Response, body: any) {
    super()
    this.code = response.status
    if (body.error) {
      this.error = body.error
    } else {
      this.body = body
    }
  }
}

const request = async (url: string, body?: any, options: Options = {}) => {

  const response = await fetch(url, {
    method: options.method || (body? "POST": "GET"),
    body: (body && typeof body !== "string")? JSON.stringify(body): body,
    headers: {
      "Content-Type": typeof body === "string"? "text/plain": "application/json"
    }
  })

  const data = await response.json()

  if (response.status >= 400) {
    throw new HttpError(response, data)
  }

  return data
}

export default request