import {MediaStreamConstraints} from "./typings";
import {User} from "./User";

export class RemoteUser<T> extends User<T> {
  async produce(offer: any, constraints: MediaStreamConstraints, streamName: string): Promise<RTCSessionDescriptionInit | null> {
    const stream = this.initStream(streamName);
    return await stream.handleProduce(offer, constraints);
  }

  stopProduce(streamName: string): void {
    this.closeStream(streamName);
  }
}
