import request from "../utils/api";
import { MediaServerRoom, LocalUser } from "./MediaServerRoom";
import { nanoid } from "nanoid";
import { action, makeObservable, observable } from "mobx";

type UserInfo = { contactId: number, name: string }

const names = ["Sigge", "Kerr", "Gunne", "Randolf", "Erna", "Torbjorn", "Bjorg", "Sindri"]

class Store {

  // url = "http://127.0.0.1:8000"
  // url = "http://srv-svc-001.greendatasoft.local:8000"
  // url = "https://mediaserver.junica.io" //"http://srv-svc-001.greendatasoft.local:8000"
  url = "https://streaming.easix.ru"
  peer = new MediaServerRoom<UserInfo>()

  roomInfo: { uuid: string } | null = null

  messages: { name?: string, contactId: number, text: string }[] = []

  constructor() {
    const name = names[Math.trunc(Math.random() * names.length)]
    this.peer.currentUser = new LocalUser({ contactId: 1, name })
    this.peer.currentUser.createStream = this.createStream

    this.peer.on("message", this.addMessage.bind(this))

    makeObservable(this, {
      roomInfo: observable,
      messages: observable,
      activeRecording: observable,
      setRoomInfo: action,
      addMessage: action
    })
  }

  init() {
    this.connect()
    return () => this.peer.disconnect()
  }

  token!: string
  async connect() {
    const { token, roomInfo } = await request(this.url + "/rooms/test", {
      roomInfo: { 
        uuid: nanoid(4),
        roomName: "First name"
       },
      userInfo: this.peer.currentUser!.userInfo,
      displayName: this.peer.currentUser!.userInfo.name,
      privateUserInfo: {
        storedUserToken: nanoid(4)
      }
    })
    this.peer.connect(this.url.replace("http", "ws")+"/ws", token)
    this.setRoomInfo(roomInfo)
    this.token = token
  }

  async createStream(streamName: string, constraints: { audio?: boolean, video?: boolean }) {
    try {
      if (streamName === "") {
        return await navigator.mediaDevices.getUserMedia({
          audio: constraints.audio,
          video: constraints.video? {
            width: 1280,
            height: 720
          }: false
        })
      }

      if (streamName === "screenSharing") {
        console.log(streamName, constraints)
        return await navigator.mediaDevices.getDisplayMedia({
          video: {
            height: { max: 1080 },
            width: { max: 1920 }
          }
        }) 
      }
    } catch (e) {
      return null
    }

    return null
  }

  setRoomInfo(roomInfo: typeof this.roomInfo) {
    this.roomInfo = roomInfo
  }

  sendMessage(text: string) {
    if (!this.peer.currentUser) return
    this.peer.sendMessage(text)
    this.addMessage(this.peer.currentUser.userInfo, text)
  }

  addMessage(userInfo: UserInfo, text: string) {
    this.messages.push({ name: userInfo.name, contactId: userInfo.contactId, text })
  }

  activeRecording = false
  toggleRecord() {
    this.activeRecording = !this.activeRecording
    if (this.activeRecording) {
      this.peer.startRecord()
    } else {
      this.peer.stopRecord()
    }
  }

  tryReconnect() {
    this.peer.tryReconnect()
  }

  closeRoom() {
    this.peer.closeRoom()
  }
}

export default Store