import {Client} from "@stomp/stompjs";
import {action, makeObservable, observable, when} from "mobx";
import {MultiMap} from "../../utils/multimap";
import {LocalUser} from "./LocalUser";
import {RemoteUser} from "./RemoteUser";
import {MediaStreamConstraints} from "./typings";

export class MediaServerRoom<T> {
  private client: Client | null = null;
  private readonly events = new MultiMap<string, (...args: any) => void>();
  users = observable.map<string, RemoteUser<T>>([]);
  currentUser: LocalUser<T> | null = null;

  status: "disconnected" | "connecting" | "connected" = "disconnected";
  connectionLost = false;

  constructor() {
    makeObservable(this, {
      currentUser: observable.ref,
      status: observable,
      connectionLost: observable,
      setStatus: action,
      addUser: action,
      removeUser: action,
      disconnect: action,
      setConnectionLost: action,
    });
  }

  connect(wsUrl: string, token: string): void {
    const client = new Client({brokerURL: wsUrl});
    this.client = client;
    client.heartbeatOutgoing = 5000;
    client.heartbeatIncoming = 5000;
    client.connectHeaders = {token};
    client.activate();

    client.reconnectDelay = 2000;
    client.onConnect = this.onStompConnect.bind(this);
    client.onStompError = () => {
      this.emit("stompFailed");
    };
    client.onWebSocketError = action(e => {
      this.setConnectionLost(true);
    });
    this.setStatus("connecting");
  }

  disconnect(): void {
    this.client?.deactivate();
    this.currentUser?.dispose();
    for (const user of this.users.values()) {
      user.dispose();
    }
    this.users.clear();
  }

  setStatus(status: "disconnected" | "connecting" | "connected"): void {
    this.status = status;
  }

  setConnectionLost(value: boolean): void {
    this.connectionLost = value;
  }

  async setConstraints(newConstraints: Partial<MediaStreamConstraints>, streamName: string): Promise<void> {
    if (!this.currentUser) return;
    const resp = await this.currentUser.changeConstraints(newConstraints, streamName);
    if (!resp) return;

    if (this.status !== "connected") {
      await when(() => this.status === "connected");
    }
    if (!resp.constraints.audio && !resp.constraints.video) {
      this.currentUser.closeStream(streamName);
      this.send("/stopBroadcast", {streamName});
      return;
    }

    const tracks = this.currentUser.streams[streamName].mediaStream?.getTracks();
    if (tracks) {
      for (const track of tracks) {
        track.onended = () => {
          this.setConstraints({[track.kind]: false}, streamName);
        };
      }
    }

    this.send("/broadcast", {...resp, streamName});
  }

  toggleConstraint(constraintsName: "audio" | "video", streamName: string = ""): void {
    if (!this.currentUser) return;

    this.setConstraints({[constraintsName]: !this.getConstraint(constraintsName, streamName)}, streamName);
  }

  getConstraint(constraintsName: "audio" | "video", streamName: string = ""): boolean {
    if (!this.currentUser) return false;
    return !!this.currentUser.streams[streamName]?.constraints[constraintsName];
  }

  private onStompConnect(): void {
    if (!this.client) return;
    this.setStatus("connected");
    this.setConnectionLost(false);
    this.client.subscribe("/room", message => {
      const data = JSON.parse(message.body);
      if (data.type === "userConnected") {
        return this.addUser(data.userId, data.userInfo);
      }
      if (data.type === "userLeaved") {
        return this.removeUser(data.userId);
      }
      if (data.type === "userBroadcast") {
        return this.userBroadcast(data.userId, data.offer, data.constraints, data.streamName);
      }
      if (data.type === "userStopBroadcast") {
        return this.userStopBroadcast(data.userId, data.streamName);
      }
      if (data.type === "consumeBroadcast") {
        this.currentUser?.consume(data.answer, data.streamName);
      }
      if (data.type === "message") {
        this.emit("message", data.userInfo, data.body)
      }
      if (data.type === "heartbeat") {
        this.client?.publish({ destination: "/heartbeat" });
      }
    });
  }

  addUser(userId: string, userInfo: T): void {
    const user = new RemoteUser(userId, userInfo);
    this.users.set(userId, user);
    this.emit("userAdded", user);
  }

  removeUser(userId: string): void {
    const user = this.users.get(userId);
    if (!user) return;
    user.dispose();
    this.users.delete(userId);
    this.emit("userLeft", user);
  }

  sendMessage(body: any) {
    this.send("/message", body)
  }

  startRecord() {
    this.send("/start-record", null)
  }

  stopRecord() {
    this.send("/stop-record", null)
  }

  closeRoom() {
    this.send("/close-room", null)
  }

  tryReconnect(){ 
    const remoteUser = Array.from(this.users.values())[0];
    remoteUser.closeStream("")
    this.send("/reconnectBroadcast", { userId: remoteUser.id, streamName: "" })
  }

  // Set quality for input stream. 0 - worst, 1 - medium, 2 - best
  setPreferredLayer(userId: string, streamName: string, layer: number) {
    this.send("/setPreferredLayer", { userId, streamName, layer, otherLayers: 0 })
  }

  private async userBroadcast(userId: string, offer: any, constraints: any, streamName: string): Promise<void> {
    const user = this.users.get(userId);
    if (!user) return;

    const answer = await user.produce(offer, constraints, streamName);
    if (answer) {
      this.send("/consumeBroadcast", {userId, answer, streamName});
    }
  }

  private async userStopBroadcast(userId: string, streamName: string): Promise<void> {
    const user = this.users.get(userId);
    if (!user) return;

    user.stopProduce(streamName);
    this.emit("userStopBroadcast", userId, streamName);
  }

  private send(destination: string, body: any): void {
    this.client?.publish({
      destination,
      body: JSON.stringify(body),
      headers: {"content-type": "application/json"},
    });
  }

  on<T extends (...args: any) => void>(channel: string, callback: T): T {
    this.events.add(channel, callback);
    return callback;
  }

  private emit(channel: string, ...args: any): void {
    this.events.forEach(channel, emit => emit(...args));
  }
}
