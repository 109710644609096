import {action, computed, makeObservable, observable} from "mobx";
import {Stream} from "./Stream";
import type {MediaStreamConstraints} from "./typings";

export class User<T> {
  id: string;
  streams: {[key: string]: Stream} = {};

  userInfo: T;
  constructor(userId: string, userInfo: T) {
    this.userInfo = userInfo;
    this.id = userId;

    makeObservable(this, {
      streams: observable,
      rawStreams: computed,
      initStream: action,
      closeStream: action,
      hasAnyVideo: computed,
    });
  }

  get rawStreams(): Array<{streamName: string; mediaStream: MediaStream; constraints: MediaStreamConstraints}> {
    const streams: Array<{streamName: string; mediaStream: MediaStream; constraints: MediaStreamConstraints}> = [];
    for (const streamName in this.streams) {
      const stream = this.streams[streamName];
      if (!stream.mediaStream) continue;
      streams.push({
        streamName,
        mediaStream: stream.mediaStream,
        constraints: stream.constraints,
      });
    }
    return streams;
  }

  initStream(streamName: string): Stream {
    const stream = this.streams[streamName];
    if (stream) return stream;

    const newStream = new Stream({delayToOpen: streamName === "screenSharing" ? 500 : 300, name: streamName});
    newStream.onFailed = () => this.closeStream(streamName);
    this.streams[streamName] = newStream;
    return newStream;
  }

  closeStream(streamName: string): boolean {
    const stream = this.streams[streamName];
    if (!stream) return false;

    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete this.streams[streamName];
    return stream.close();
  }

  dispose(): void {
    for (const key in this.streams) {
      this.streams[key].close();
    }
  }

  hasVideo(streamName: string): boolean {
    const stream = this.streams[streamName];
    if (!stream) return false;

    return stream.constraints.video && !!stream.mediaStream && stream.ready;
  }

  get hasAnyVideo(): boolean {
    return this.hasVideo("") || this.hasVideo("screenSharing");
  }
}
