import {Stream} from ".";
import {MediaStreamConstraints} from "./typings";
import {User} from "./User";

export class LocalUser<T> extends User<T> {
  constructor(userInfo: T) {
    super("current", userInfo);
    this.createStream = async () => null;
  }

  createStream: (streamName: string, constraints: Partial<MediaStreamConstraints>) => Promise<MediaStream | null>;

  async changeConstraints(newConstraints: Partial<MediaStreamConstraints>, streamName: string): Promise<false | ReturnType<Stream["produce"]>> {
    const tracks: MediaStreamTrack[] = [];
    if (newConstraints.audio === true || newConstraints.video === true) {
      const mediaStream = await this.createStream(streamName, newConstraints);
      if (!mediaStream) return false;

      tracks.push(...mediaStream.getTracks());
    }

    const stream = this.initStream(streamName);
    return await stream.produce(tracks, newConstraints);
  }

  consume(answer: RTCSessionDescriptionInit, streamName: string): void {
    const stream = this.streams[streamName];
    if (!stream) return;
    stream.consume(answer);
  }
}
