import { FunctionComponent, useEffect, useRef } from "react"

type VideoProps = {
  stream: MediaStream
} & React.VideoHTMLAttributes<HTMLVideoElement>

const Video: FunctionComponent<VideoProps> = ({ stream, ...otherProps }) => {

  const videoRef = useRef<HTMLVideoElement>(null)
  useEffect(() => {
    if (!videoRef.current) return
    videoRef.current.srcObject = stream
    videoRef.current.muted = false
  }, [ stream ])

  return (
    <video 
      ref={videoRef} 
      autoPlay={true} 
      playsInline={true} 
      {...otherProps} 
    />
  )
}

export default Video