import {action, makeObservable, observable} from "mobx";
import {MediaStreamConstraints} from "./typings";

interface StreamOptions {
  delayToOpen?: number;
  name?: string
}

export class Stream {
  name: string
  constraints: MediaStreamConstraints = {audio: false, video: false};
  mediaStream: MediaStream | null = null;
  pc: RTCPeerConnection;
  ready = false;

  onFailed: () => void = () => {};
  private readonly options: StreamOptions;

  constructor(options: StreamOptions = {}) {
    this.name = options.name ?? ""
    this.options = options;
    makeObservable(this, {
      constraints: observable,
      mediaStream: observable.ref,
      ready: observable,
      setConstraints: action,
      onTrack: action.bound,
    });
    this.pc = new RTCPeerConnection();
    this.pc.addEventListener("connectionstatechange", e => {
      if (this.pc.connectionState === "failed") {
        this.onFailed();
      }
    });
    this.pc.addEventListener("track", this.onTrack);
  }

  async produce(
    tracks: MediaStreamTrack[],
    constraints: Partial<MediaStreamConstraints>
  ): Promise<{constraints: MediaStreamConstraints; offer?: RTCSessionDescriptionInit}> {
    this.pc.removeEventListener("track", this.onTrack);
    if (!this.mediaStream) {
      this.mediaStream = new MediaStream(tracks);
      this.ready = true;
    }

    this.setConstraints(constraints);
    let updateSdp = false;
    for (const track of tracks) {
      const result = this.addTrack(track);
      if (result) {
        updateSdp = true;
      }
    }
    this.applyConstraints();

    if (updateSdp) {
      const offer = await this.pc.createOffer();
      console.log(offer)
      await this.pc.setLocalDescription(offer);
      return {offer, constraints: this.constraints};
    }
    return {constraints: this.constraints};
  }

  async consume(answer: RTCSessionDescriptionInit): Promise<void> {
    console.log(answer)
    await this.pc.setRemoteDescription(answer);
  }

  async handleProduce(offer: RTCSessionDescriptionInit, constraints: MediaStreamConstraints): Promise<RTCSessionDescriptionInit | null> {
    this.setConstraints(constraints);
    if (offer) {
      await this.pc.setRemoteDescription(offer);
      const answer = await this.pc.createAnswer();
      await this.pc.setLocalDescription(answer);
      return answer;
    }
    return null;
  }

  setConstraints(constraints: Partial<MediaStreamConstraints>): void {
    this.constraints = {
      ...this.constraints,
      ...constraints,
    };
  }

  onTrack(e: RTCTrackEvent): void {
    console.log("On track", e.streams[0])
    if (!e.streams[0]) return;
    this.mediaStream = e.streams[0];
    if (!this.ready && this.options.delayToOpen) {
      setTimeout(
        action(() => {
          this.ready = true;
        }),
        this.options.delayToOpen
      );
    }
  }

  close(): boolean {
    if (this.pc.connectionState === "closed") {
      return false;
    }
    for (const sender of this.pc.getSenders()) {
      if (!sender.track) continue;
      sender.track.onended = null;
      sender.track.stop();
    }
    for (const receiver of this.pc.getReceivers()) {
      receiver.track?.stop();
    }
    this.pc.close();

    return true;
  }

  // Вспомогательные методы
  private addTrack(track: MediaStreamTrack): boolean {
    for (const sender of this.pc.getSenders()) {
      const currentTrack = sender.track;
      if (!currentTrack || currentTrack.kind !== track.kind) continue;

      sender.replaceTrack(track);
      this.mediaStream?.removeTrack(currentTrack);
      this.mediaStream?.addTrack(track);

      currentTrack.onended = null;
      currentTrack.stop();

      return false;
    }
    if (track.kind === "video") {
      this.pc.addTransceiver(track, {
        direction: "sendonly",
        sendEncodings: [
          { rid: 'low', scaleResolutionDownBy: 4.0, maxBitrate: 100000 }, // Самое низкое качество
          { rid: 'medium', scaleResolutionDownBy: 2.0, maxBitrate: 400000 }, // Среднее качество
          { rid: 'high', scaleResolutionDownBy: 1.0, maxBitrate: 1000000 }, // Высокое качество
        ]
      });
    } else {
      this.pc.addTrack(track);
    }
    this.mediaStream?.addTrack(track)
    return true;
  }

  private applyConstraints(): void {
    for (const sender of this.pc.getSenders()) {
      if (!sender.track || sender.track.readyState !== "live") continue;
      if (!this.constraints[sender.track.kind as "video" | "audio"]) {
        sender.track.stop();
      }
    }
  }
}
